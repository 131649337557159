
import { defineComponent } from 'vue'
import type { DeadlineType } from '@/definitions/_general/_types/types'
import type { PropType } from 'vue'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  props: {
    deadlineDate: {
      type: Object as PropType<DeadlineType>,
      required: true,
    },
    formattedDate: {
      type: Boolean,
    },
    iconLeft: {
      type: Boolean,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
})
