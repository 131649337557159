
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TmBadgeCounter',
  props: {
    label: {
      type: [String, Number],
      default: '',
    },
  },
})
