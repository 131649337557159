import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_activities = _resolveComponent("home-activities")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_empty_state = _resolveComponent("tm-empty-state")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.isEmptyMode)
      ? (_openBlock(), _createBlock(_component_home_activities, {
          key: 0,
          activities: _ctx.computedActivities,
          actions: _ctx.computedActions
        }, null, 8, ["activities", "actions"]))
      : (_openBlock(), _createBlock(_component_tm_empty_state, _normalizeProps(_mergeProps({ key: 1 }, _ctx.emptyStateComputed)), {
          footer: _withCtx(() => [
            _createVNode(_component_tm_button, {
              size: "large",
              color: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.emptyStateComputed.btnText), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 16))
  ]))
}