
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { HomeActivityActionType, HomeActivityType } from '@/definitions/home/types'
import TmBadgeCounter from '@/components/shared/TmBadgeCounter.vue'
import DeadlineDate from '@/components/pages/shared/DeadlineDate.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmPriority from '@/components/shared/TmPriority.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'

export default defineComponent({
  components: {
    TmAvatar,
    TmPriority,
    TmDropdownItem,
    TmStatus,
    TmButton,
    TmDropdown,
    DeadlineDate,
    TmBadgeCounter,
  },
  props: {
    activities: {
      type: Array as PropType<HomeActivityType[]>,
    },
    actions: {
      type: Array as PropType<HomeActivityActionType[]>,
    },
  },
})
