
import { computed, defineComponent } from 'vue'
import HomeActivities from '@/components/pages/home/HomeActivities.vue'
import TmEmptyState from '@/components/shared/TmEmptyState.vue'
import TmButton from '@/components/shared/TmButton.vue'
import {
  homeChatsActivities,
  homeChatsActivitiesActions,
  homeDealsActivities,
  homeDealsActivitiesActions,
  homeTasksActivities,
  homeTasksActivitiesActions,
  homeTicketsActivities,
  homeTicketsActivitiesActions
} from '@/definitions/home/data'
import { useRoute } from 'vue-router'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmEmptyState,
    TmButton,
    HomeActivities,
  },
  setup() {
    const route = useRoute()
    const { isEmptyMode } = useModes()

    const computedActivities = computed(() => {
      switch (route.params.view) {
        case 'tasks': return homeTasksActivities
        case 'tickets': return homeTicketsActivities
        case 'deals': return homeDealsActivities
        default: return homeChatsActivities
      }
    })
    const computedActions = computed(() => {
      switch (route.params.view) {
        case 'tasks': return homeTasksActivitiesActions
        case 'tickets': return homeTicketsActivitiesActions
        case 'deals': return homeDealsActivitiesActions
        default: return homeChatsActivitiesActions
      }
    })

    const chatEmpty = {
      icon: 'tmi-chat-outline',
      title: 'No open chats assigned to you',
      description: 'Start a new chat or check messenger for available assignments.',
      btnText: 'Open messenger',
    }
    const tasksEmpty = {
      icon: 'tmi-library-add-check-outline',
      title: 'No tasks assigned to you',
      description: 'Create a new task or check existing tasks for available assignments.',
      btnText: 'New tasks',
    }
    const ticketsEmpty = {
      icon: 'tmi-email-outline',
      title: 'No tickets assigned to you',
      description: 'Create a new ticket or check existing tickets for available assignments.',
      btnText: 'New ticket',
    }
    const dealsEmpty = {
      icon: 'tmi-paid-outline',
      title: 'No deals assigned to you',
      description: 'Create a new deal or check existing deals for available assignments.',
      btnText: 'New deal',
    }

    const emptyStateComputed = computed(() => {
      switch (route.params.view) {
        case 'tasks': return tasksEmpty
        case 'tickets': return ticketsEmpty
        case 'deals': return dealsEmpty
        default: return chatEmpty
      }
    })

    return {
      isEmptyMode,
      emptyStateComputed,
      computedActions,
      computedActivities,
    }
  },
})
