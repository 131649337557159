import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["deadline-date", {
      'font-weight-medium': _ctx.deadlineDate.type,
      'error--text': _ctx.deadlineDate.type === 'danger',
      'warning--text': _ctx.deadlineDate.type === 'warning',
      'deadline-date--reverse': _ctx.iconLeft,
    }])
  }, [
    _createElementVNode("span", null, _toDisplayString(_ctx.formattedDate ? _ctx.formatDate(_ctx.deadlineDate.date) : _ctx.deadlineDate.date), 1),
    (_ctx.deadlineDate.type)
      ? (_openBlock(), _createBlock(_component_tm_icon, {
          key: 0,
          name: _ctx.deadlineDate.type === 'warning' ? 'warning' : 'info'
        }, null, 8, ["name"]))
      : _createCommentVNode("", true)
  ], 2))
}