import type { HomeActivityActionType, HomeActivityType, HomeViewLinkType } from '@/definitions/home/types'
import { ticketsSidebarRoutes, ticketsSystemSidebarRoutes } from '@/router/sidebarRoutes/ticketsRoutes'
import { tasksSidebarRoutes } from '@/router/sidebarRoutes/tasksRoutes'
import type { SelectEntityCategoryDataType, SelectEntityCategoryType } from '@/definitions/modals/shared/selectEntity/type'
import type { ReportCardType } from '@/definitions/shared/templates/types'
import { computed } from 'vue'
import { useModes } from '@/compositions/modes'

const { isEmptyMode } = useModes()

export const pinnedViews: HomeViewLinkType[] = [
  {
    title: 'Support tickets',
    subtitle: 'Helpdesk',
    emoji: 'jacket',
  },
  {
    icon: 'tmi-pie-chart-outline',
    title: 'Autumn campaign clients',
    subtitle: 'Contacts',
  },
  {
    title: 'Development',
    subtitle: 'Tasks',
    emoji: 'tools',
  },
]

export const recentlyViewedToday: HomeViewLinkType[] = [
  {
    icon: 'tmi-email-outline',
    title: 'My compose screen stopped working',
    subtitle: '3 min ago',
  },
  {
    url: 'https://randomuser.me/api/portraits/women/23.jpg',
    title: 'Jennifer Crowford',
    subtitle: '5 min ago',
  },
  {
    icon: 'tmi-group-outline',
    title: 'Autumn campaign clients',
    subtitle: '1 hour ago',
  },
]

export const recentlyViewedWeek: HomeViewLinkType[] = [
  {
    icon: 'tmi-chat-outline',
    title: 'Chat with (854) 434-3445',
    subtitle: '12 hours ago',
  },
  {
    url: require('@/assets/images/logos/kfc.png'),
    title: 'KFC',
    subtitle: 'Yesterday, 12:23 am',
  },
  {
    icon: 'tmi-group-outline',
    title: 'USA vip clients',
    subtitle: 'Aug 4, 3:53 pm',
  },
  {
    url: require('@/assets/images/logos/nivea.png'),
    title: 'Nivea',
    subtitle: 'Aug 4, 1:34 am',
  },
  {
    icon: 'tmi-chat-outline',
    title: 'Chat with (201) 555-0124',
    subtitle: 'Aug 2, 4:58 pm',
  },
]

export const overviewBlocks: ReportCardType[] = [
  {
    title: 'Messages sent',
    counter: computed(() => isEmptyMode.value ? '0' : '126,3K').value,
    percent: {
      change: 'green',
      value: '+12.8%',
    },
    previously: '124,4K',
  },
  {
    title: 'Messages received',
    counter: computed(() => isEmptyMode.value ? '0' : '1,677').value,
    percent: {
      change: 'red',
      value: '-9.2%',
    },
    previously: '1,701',
  },
  {
    title: 'Satisfaction rate',
    caption: 'Helpdesk',
    counter: computed(() => isEmptyMode.value ? '0.0%' : '91.7%').value,
    percent: {
      change: 'red',
      value: '-9.2%',
    },
    previously: '94.4%',
  },
  {
    title: 'Rated tickets',
    counter: computed(() => isEmptyMode.value ? '0' : '24').value,
    percent: {
      change: 'blue',
      value: '+0.0%',
    },
    previously: '24',
  },
  {
    title: 'Total recipients',
    caption: 'SMS campaigns',
    counter: computed(() => isEmptyMode.value ? '0' : '32').value,
    percent: {
      change: 'green',
      value: '+0.7%',
    },
    previously: '30',
  },
  {
    title: 'Deals win rate',
    counter: computed(() => isEmptyMode.value ? '0.0%' : '83.4%').value,
    percent: {
      change: 'green',
      value: '+12.8%',
    },
    previously: '74.9%',
  },
  {
    title: 'Tickets solved',
    counter: computed(() => isEmptyMode.value ? '0' : '46').value,
    percent: {
      change: 'green',
      value: '+2.5%',
    },
    previously: '43',
  },
  {
    title: 'New contacts',
    counter: computed(() => isEmptyMode.value ? '0' : '453').value,
    percent: {
      change: 'red',
      value: '-3.3%',
    },
    previously: '469',
  },
]

const openActivitiesStatus = {
  name: 'open',
  color: 'orange',
  outline: true,
}
const newActivitiesStatus = {
  name: 'new',
  color: 'red',
  outline: false,
}

export const homeChatsActivities: HomeActivityType[] = [
  {
    title: 'SMS chat with Ralph Edwards',
    text: 'It worked!',
    icon: 'tmi-chat-outline',
    unread: '1',
    date: {
      date: 'Just now',
      type: '',
    },
    status: openActivitiesStatus,
  },
  {
    title: 'WhatsApp chat with Arlene McCoy',
    text: 'Okay, thanks for the update.',
    image: require('@/assets/images/chats/whatsapp.svg'),
    unread: '2',
    date: {
      date: '2 mins',
      type: '',
    },
    status: openActivitiesStatus,
  },
  {
    title: 'SMS chat with Esther Howard',
    text: 'Great, thanks! I appreciate you updating. Good luck!',
    icon: 'tmi-chat-outline',
    date: {
      date: '20 Jun, 1:34 pm',
      type: '',
    },
    status: openActivitiesStatus,
  },
  {
    title: 'Live chat with Dianne Russell',
    text: 'You: Okay, thanks for the quick response.',
    icon: 'public',
    date: {
      date: '11 Jun, 10:28 am',
      type: '',
    },
    status: openActivitiesStatus,
  },
  {
    title: 'SMS chat with',
    text: 'Perfect, thanks! I was worried my package was lost, but knowing it’s in transit.',
    icon: 'tmi-chat-outline',
    date: {
      date: '28 Jul, 4:46 am',
      type: '',
    },
    status: openActivitiesStatus,
  },
]
export const homeTasksActivities: HomeActivityType[] = [
  {
    title: 'Set up a video call with the VIP client and his team',
    text: 'Let’s take a quick look at some of the TextMagic features that will help you to improve your business communication.',
    icon: 'tmi-library-add-check-outline',
    date: {
      date: '8 Jul, 7:14 am',
      type: 'danger',
    },
    priority: '400',
    status: openActivitiesStatus,
  },
  {
    title: '[BYOC] Link more numbers modal doesn\'t show all available numbers',
    text: 'The TextMagic Web app allows you to send and receive text messages very easily.',
    icon: 'tmi-library-add-check-outline',
    date: {
      date: 'Today, 12:46 pm',
      type: 'warning',
    },
    priority: '200',
    status: openActivitiesStatus,
  },
  {
    title: 'Dynamic registration of ui services in container',
    text: 'This is especially good for marketing campaigns, because you can make your text messages more personal by using mail merge tags and templates, and you can attach files to your messages.',
    icon: 'tmi-library-add-check-outline',
    date: {
      date: 'Tomorrow, 0:03 pm',
      type: 'warning',
    },
    priority: '300',
    status: {
      name: 'need attention',
      color: 'red',
      outline: true,
    },
  },
  {
    title: '[Carrera S] - Implement the My account + Switch workspace + New profile updated dropdown',
    text: 'Another feature worth mentioning is the two-way messaging capability.',
    icon: 'tmi-library-add-check-outline',
    date: {
      date: '19 Nov, 0:03 pm',
      type: '',
    },
    priority: '200',
    status: {
      name: 'in progress',
      color: 'blue',
      outline: true,
    },
  },
  {
    title: '[Notes modal window truncated] Chat details/ when the user opens the contact\'s notes',
    text: 'This allows your recipients to reply to your messages, fostering better engagement and opening up a channel for feedback.',
    icon: 'tmi-library-add-check-outline',
    date: {
      date: '15 Aug, 8:41 am',
      type: '',
    },
    priority: '100',
    status: {
      name: 'in progress',
      color: 'blue',
      outline: true,
    },
  },
]
export const homeTicketsActivities: HomeActivityType[] = [
  {
    title: 'Our business uses the login account textmagic@heal.com',
    text: 'Let’s take a quick look at some of the TextMagic features that will help you to improve your business communication.',
    icon: 'tmi-email-outline',
    date: {
      date: 'An hour ago',
      type: '',
    },
    priority: '300',
    assignee: {
      avatar: 'https://randomuser.me/api/portraits/men/41.jpg',
    },
    status: newActivitiesStatus,
  },
  {
    title: 'I have High Authority sites at reasonable prices 20S/90S For Guest Posting Perm...',
    text: 'I understand your concern. Let me check the details on our end and get back to you with a solution.',
    icon: 'tmi-email-outline',
    date: {
      date: 'Today, 7:00 am',
      type: '',
    },
    priority: '100',
    assignee: {
      avatarColor: 'green',
      fullName: 'J',
    },
    status: newActivitiesStatus,
  },
  {
    title: 'More information on Failures - Napier Port',
    text: 'Thank you for bringing this to our attention. We’ve escalated the issue to our technical team, and they’re working on it now.',
    icon: 'tmi-email-outline',
    date: {
      date: '2 Jun, 9:12 am',
      type: '',
    },
    priority: '400',
    assignee: {
      avatarColor: 'red',
      fullName: 'H',
    },
    status: openActivitiesStatus,
  },
  {
    title: 'Your buyer has filed a case',
    text: 'Can you please provide the error message you’re seeing? That will help us diagnose the problem more quickly.',
    icon: 'tmi-email-outline',
    date: {
      date: '26 May, 12:30 am',
      type: '',
    },
    priority: '200',
    assignee: {
      avatar: 'https://randomuser.me/api/portraits/women/74.jpg',
    },
    status: openActivitiesStatus,
  },
  {
    title: 'Trouble authenticating to TextMagic login page',
    text: 'I’m sorry for the inconvenience this has caused. We’ll make sure this is resolved as soon as possible.',
    icon: 'tmi-email-outline',
    date: {
      date: '1 May, 2:22 am',
      type: '',
    },
    priority: '100',
    assignee: {
      avatarColor: 'orange',
      fullName: 'M',
    },
    status: {
      name: 'pending',
      color: 'blue',
      outline: true,
    },
  },
]
export const homeDealsActivities: HomeActivityType[] = [
  {
    title: 'Nike rebrending deal',
    infos: ['$2,500,000', 'Mitsubishi'],
    icon: 'tmi-paid-outline',
    date: {
      date: '8 Jul, 7:14 am',
      type: 'danger',
    },
    status: {
      name: 'contact made',
      color: 'blue',
      outline: true,
    },
  },
  {
    title: 'Nestle new deal',
    infos: ['$500,000', 'eBay'],
    icon: 'tmi-paid-outline',
    date: {
      date: '12 Jun, 2:23 pm',
      type: 'warning',
    },
    status: {
      name: 'proposal made',
      color: 'violet',
      outline: true,
    },
  },
  {
    title: 'Urgent PC deal',
    infos: ['$200,000', 'Apple'],
    icon: 'tmi-paid-outline',
    date: {
      date: '19 Nov, 0:03 pm',
      type: 'warning',
    },
    status: {
      name: 'negotiation started',
      color: 'orange',
      outline: true,
    },
  },
  {
    title: 'Support4You deal',
    infos: ['$1,600,000', 'L\'Oréal'],
    icon: 'tmi-paid-outline',
    date: {
      date: '19 Nov, 0:03 pm',
      type: '',
    },
    status: {
      name: 'contact made',
      color: 'blue',
      outline: true,
    },
  },
  {
    title: 'Inspiration priority deal',
    infos: ['$40,000', 'MasterCard'],
    icon: 'tmi-paid-outline',
    date: {
      date: '15 Aug, 8:41 am',
      type: '',
    },
    status: {
      name: 'qualified',
      color: 'brown',
      outline: true,
    },
  },
]

export const pinnedViewsMessengerCategory: SelectEntityCategoryDataType[] = [
  {
    itemType: 'tab',
    icon: 'tmi-forum-outline',
    title: 'Open chats',
  },
  {
    itemType: 'tab',
    icon: 'tmi-forum-outline',
    title: 'Unread chats',
  },
  {
    itemType: 'tab',
    icon: 'tmi-forum-outline',
    title: 'Closed chats',
  },
  {
    itemType: 'tab',
    icon: 'tmi-forum-outline',
    title: 'All chats',
  },
]

export const pinnedViewsHelpdeskCategory: SelectEntityCategoryDataType[] = ticketsSidebarRoutes.concat(ticketsSystemSidebarRoutes).map((e: any) => {
  return {
    itemType: e.itemType === 'link' ? 'tab' : 'title',
    title: e.title,
    emoji: e.emoji,
  }
})

export const pinnedViewsContactsCategory: SelectEntityCategoryDataType[] = [
  {
    itemType: 'tab',
    icon: 'tmi-pie-chart-outline',
    title: 'Marketing list',
  },
  {
    itemType: 'tab',
    icon: 'tmi-pie-chart-outline',
    title: 'Import-13-07-17',
  },
  {
    itemType: 'tab',
    icon: 'tmi-pie-chart-outline',
    title: 'For Zapier',
  },
  {
    itemType: 'tab',
    icon: 'tmi-pie-chart-outline',
    title: 'VIP Clients',
  },
  {
    itemType: 'tab',
    icon: 'tmi-pie-chart-outline',
    title: 'Super long list name to show how easy we cut name',
  },
  {
    itemType: 'tab',
    icon: 'tmi-pie-chart-outline',
    title: 'Import-10-05-18',
  },
  {
    itemType: 'tab',
    icon: 'tmi-pie-chart-outline',
    title: 'My New List',
  },
  {
    itemType: 'tab',
    icon: 'tmi-pie-chart-outline',
    title: 'Import-13-07-17',
  },
  {
    itemType: 'tab',
    icon: 'tmi-pie-chart-outline',
    title: 'Date test',
  },
]

export const pinnedViewsTasksCategory: SelectEntityCategoryDataType[] = tasksSidebarRoutes.map((e: any) => {
  return {
    itemType: e.itemType === 'link' ? 'tab' : 'title',
    title: e.title,
    emoji: e.emoji,
  }
})

export const pinnedViewsDealsCategory: SelectEntityCategoryDataType[] = [
  {
    itemType: 'tab',
    emoji: 'us',
    title: 'Sales US',
  },
  {
    itemType: 'tab',
    emoji: 'uk',
    title: 'Sales UK',
  },
]

export const homeChatsActivitiesActions: HomeActivityActionType[] = [
  {
    label: 'View chat',
    icon: 'forum',
  },
  {
    label: 'Mark chat as read',
    icon: 'mark_as_unread',
  },
  {
    icon: 'person',
    label: 'View contact',
  },
]

export const homeTasksActivitiesActions: HomeActivityActionType[] = [
  {
    label: 'View details',
    icon: 'visibility',
  },
  {
    label: 'Move forward',
    icon: 'arrow_forward',
  },
  {
    icon: 'link',
    label: 'Copy link',
  },
  {
    icon: 'tmi-duplicate',
    label: 'Duplicate',
  },
  {
    icon: 'delete',
    label: 'Delete',
  },
]

export const homeTicketsActivitiesActions: HomeActivityActionType[] = [
  {
    label: 'View details',
    icon: 'visibility',
  },
  {
    label: 'Mark as read',
    icon: 'drafts',
  },
  {
    icon: 'account_circle',
    label: 'Assign',
  },
  {
    icon: 'tmi-followers-add',
    label: 'Follow',
  },
  {
    icon: 'call_merge',
    label: 'Merge into...',
  },
  {
    icon: 'report',
    label: 'Mark as spam',
  },
  {
    icon: 'delete',
    label: 'Delete',
  },
]

export const homeDealsActivitiesActions: HomeActivityActionType[] = [
  {
    label: 'View details',
    icon: 'visibility',
  },
  {
    label: 'Mark as won',
    icon: 'verified',
  },
  {
    icon: 'tmi-unverified',
    label: 'Mark as lost',
  },
  {
    icon: 'arrow_forward',
    label: 'Move forward',
  },
  {
    icon: 'tmi-person-assign',
    label: 'Transfer ownership',
  },
  {
    icon: 'tmi-duplicate',
    label: 'Duplicate',
  },
  {
    icon: 'delete',
    label: 'Delete',
  },
]

export const pinnedViewsCategories: SelectEntityCategoryType[] = [
  {
    title: 'Messenger',
    name: 'messenger',
    icon: 'tmi-chat-outline',
    data: pinnedViewsMessengerCategory,
  },
  {
    title: 'Helpdesk',
    name: 'helpdesk',
    icon: 'tmi-email-outline',
    data: pinnedViewsHelpdeskCategory,
  },
  {
    title: 'Contacts',
    name: 'contacts',
    icon: 'tmi-group-outline',
    data: pinnedViewsContactsCategory,
  },
  {
    title: 'Tasks',
    name: 'tasks',
    icon: 'tmi-library-add-check-outline',
    data: pinnedViewsTasksCategory,
  },
  {
    title: 'Deals',
    name: 'deals',
    icon: 'tmi-paid-outline',
    data: pinnedViewsDealsCategory,
  },
]

export const replaceInsightCampaignsData: SelectEntityCategoryDataType[] = [
  {
    itemType: 'title',
    title: 'Sms campaigns',
  },
  {
    itemType: 'tab',
    icon: 'person',
    title: 'Total recipients',
    text: 'Total number of people who received SMS campaigns.',
    isAdded: true,
  },
  {
    itemType: 'tab',
    icon: 'done_all',
    title: 'Delivery rate',
    text: 'Percentage of successfully delivered SMS out of the total number of send messages.',
  },
  {
    itemType: 'tab',
    icon: 'done_all',
    title: 'Total delivered',
    text: 'Total number of successfully delivered SMS.',
  },
  {
    itemType: 'tab',
    icon: 'do_not_disturb_on',
    title: 'Unsubscribe rate',
    text: 'Percentage of recipients who opted out of SMS divided by the total number of delivered SMS.',
  },
  {
    itemType: 'tab',
    icon: 'do_not_disturb_on',
    title: 'Total unsubscribed',
    text: 'Total number of recipients who opted out of future SMS messages.',
  },
  {
    itemType: 'title',
    title: 'Email campaigns',
  },
  {
    itemType: 'tab',
    icon: 'person',
    title: 'Total recipients',
    text: 'Total number of people who received the email campaigns.',
  },
  {
    itemType: 'tab',
    icon: 'done_all',
    title: 'Delivery rate',
    text: 'Percentage of successfully delivered emails out of the total number of send emails.',
  },
  {
    itemType: 'tab',
    icon: 'done_all',
    title: 'Total delivered',
    text: 'Total number of successfully delivered emails.',
  },
  {
    itemType: 'tab',
    icon: 'drafts',
    title: 'Open rate',
    text: 'Percentage of recipients who opened an email out of the total number of delivered emails.',
  },
  {
    itemType: 'tab',
    icon: 'drafts',
    title: 'Total opens',
    text: 'Total number of recipients who opened an email.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-cursor',
    title: 'Click rate',
    text: 'Percentage of unique email responses out of the total number of delivered emails.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-cursor',
    title: 'Total clicks',
    text: 'Total number of unique email responses.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-email-reply',
    title: 'Response rate',
    text: 'Percentage of recipients who clicked a link in an email out of the total number of delivered emails.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-email-reply',
    title: 'Total responses',
    text: 'Total number of recipients who clicked on a link in the email.',
  },
  {
    itemType: 'tab',
    icon: 'do_not_disturb_on',
    title: 'Unsubscribe rate',
    text: 'Percentage of recipients who opted out of emails divided by the total number of delivered emails.',
  },
  {
    itemType: 'tab',
    icon: 'do_not_disturb_on',
    title: 'Total unsubscribed',
    text: 'Total number of recipients who opted out of future email messages.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-email-cancel',
    title: 'Bounce rate',
    text: 'Percentage of emails that couldn’t be delivered out of the total number of sent emails.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-email-cancel',
    title: 'Total bounces',
    text: 'Total number of emails that couldn’t be delivered.',
  },
  {
    itemType: 'tab',
    icon: 'report',
    title: 'Spam reports rate',
    text: 'Percentage of emails that recipients marked as spam out of the total number of delivered emails.',
  },
  {
    itemType: 'tab',
    icon: 'report',
    title: 'Total spam reports',
    text: 'Total number of emails that recipients marked as spam.',
  },
]

export const replaceInsightMessengerData: SelectEntityCategoryDataType[] = [
  {
    itemType: 'title',
    title: 'Sms',
  },
  {
    itemType: 'tab',
    icon: 'tmi-message-send',
    title: 'Messages sent',
    text: 'The total number of outbound SMS messages.',
    isAdded: true,
  },
  {
    itemType: 'tab',
    icon: 'tmi-message-received',
    title: 'Messages received',
    text: 'The total number of inbound SMS messages.',
    isAdded: true,
  },
  {
    itemType: 'tab',
    icon: 'tmi-reply',
    title: 'Reply rate',
    text: 'Percentage of inbound SMS messages out of the total number of send messages.',
  },
  {
    itemType: 'title',
    title: 'Live chat',
  },
  {
    itemType: 'tab',
    icon: 'tmi-forum-outline',
    title: 'New live chats',
    text: 'Total number of new live chats.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-checkbox-alt-circle',
    title: 'Solved live chats',
    text: 'Total number of marked as resolved live chats.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-warning-outline',
    title: 'Missed chats',
    text: 'Total number of live chats that were not answered before the visitor left the chat.',
  },
  {
    itemType: 'tab',
    icon: 'schedule',
    title: 'Avg. first response time',
    text: 'Average time it takes to respond to an initial chat request.',
  },
  {
    itemType: 'tab',
    icon: 'timelapse',
    title: 'Avg. resolution time',
    text: 'Average time taken to solve live chat.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-thumbs-up-down-outline',
    title: 'Rated live chats',
    text: 'Total number of rated live chats.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-satisfaction-rate',
    title: 'Satisfaction rate',
    text: 'Percentage of live chats rated “Good” out of the total number of rated live chats.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-thumb-up-outline',
    title: 'Good rated chats',
    text: 'Total number of live chats rated “Good.” ',
  },
  {
    itemType: 'tab',
    icon: 'tmi-thumb-down-outline',
    title: 'Poor rated chats',
    text: 'Total number of live chat rated “Poor.”',
  },
  {
    itemType: 'title',
    title: 'Whatsapp',
  },
  {
    itemType: 'tab',
    icon: 'tmi-whatsup',
    title: 'New WhatsApp chats',
    text: 'Total number of new WhatsApp chats.',
  },
  {
    itemType: 'tab',
    icon: 'schedule',
    title: 'Avg. first response time',
    text: 'Average time it takes to respond to an initial chat request.',
  },
  {
    itemType: 'title',
    title: 'Voice calls',
  },
  {
    itemType: 'tab',
    icon: 'tmi-phone-callback-outline',
    title: 'Inbound calls',
    text: 'Total number of inbound voice calls.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-phone-forwarded2-outline',
    title: 'Outbound calls',
    text: 'Total number of outbound voice calls.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-phone-missed-outline',
    title: 'Missed calls',
    text: 'Total number of inbound voice calls that were not answered.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-phone-paused-outline',
    title: 'Avg. queue wait time',
    text: 'Average time callers spent waiting in the queue before the call was answered.',
  },
  {
    itemType: 'tab',
    icon: 'timelapse',
    title: 'Avg. call length',
    text: 'Average duration of a voice call from the time it is answered until it ends.',
  },
]

export const replaceInsightHelpdeskData: SelectEntityCategoryDataType[] = [
  {
    itemType: 'tab',
    icon: 'tmi-mark-email-unread-outline',
    title: 'New tickets',
    text: 'Total number of new tickets.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-mark-email-read',
    title: 'Tickets solved',
    text: 'Total number of marked as resolved tickets.',
    isAdded: true,
  },
  {
    itemType: 'tab',
    icon: 'schedule',
    title: 'Avg. first response time ',
    text: 'Average time it takes to respond to an initial email request.',
  },
  {
    itemType: 'tab',
    icon: 'timelapse',
    title: 'Avg. resolution time',
    text: 'Average time taken to solve ticket.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-thumbs-up-down-outline',
    title: 'Rated tickets',
    text: 'Total number of rated tickets.',
    isAdded: true,
  },
  {
    itemType: 'tab',
    icon: 'tmi-satisfaction-rate',
    title: 'Satisfaction rate',
    text: 'Percentage of tickets rated “Good” out of the total number of rated tickets.',
    isAdded: true,
  },
  {
    itemType: 'tab',
    icon: 'tmi-thumb-up-outline',
    title: 'Good rated tickets',
    text: 'Total number of tickets rated “Good.” ',
  },
  {
    itemType: 'tab',
    icon: 'tmi-thumb-down-outline',
    title: 'Poor rated tickets',
    text: 'Total number of tickets rated “Poor.”',
  },
  {
    itemType: 'tab',
    icon: 'tmi-message-quote',
    title: 'Feedback with comments',
    text: 'Total number of rated tickets that included a comment.',
  },
]

export const replaceInsightContactsData: SelectEntityCategoryDataType[] = [
  {
    itemType: 'tab',
    icon: 'tmi-person-outline',
    title: 'New contacts',
    text: 'Total number of new contacts.',
    isAdded: true,
  },
  {
    itemType: 'tab',
    icon: 'do_not_disturb_on',
    title: 'Unsubscribed contacts',
    text: 'Total number of contacts who have opted out of receiving communications.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-person-block',
    title: 'Blocked contacts',
    text: 'Total number of contacts who were blocked from incoming and outgoing communications.',
  },
  {
    itemType: 'tab',
    icon: 'domain',
    title: 'New organizations',
    text: 'Total number of new organizations.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-import-outline',
    title: 'New imports',
    text: 'Total number of new imports.',
  },
]

export const replaceInsightTasksData: SelectEntityCategoryDataType[] = [
  {
    itemType: 'tab',
    icon: 'tmi-library-add-check-outline',
    title: 'New tasks',
    text: 'Total number of new tasks.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-library-add-check-outline',
    title: 'Completed tasks',
    text: 'Total number of completed tasks.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-checkbox-alt-circle',
    title: 'Tasks completed on time',
    text: 'The total number of tasks completed within the specified deadlines.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-warning-outline',
    title: 'Tasks completed late',
    text: 'The total number of tasks completed after the specified deadlines.',
  },
  {
    itemType: 'tab',
    icon: 'timelapse',
    title: 'Avg. task completion time',
    text: 'Average time taken to complete task.',
  },
]

export const replaceInsightDealsData: SelectEntityCategoryDataType[] = [
  {
    itemType: 'tab',
    icon: 'tmi-sale',
    title: 'New deals',
    text: 'Total number of new deals.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-deals-win-rate',
    isAdded: true,
    title: 'Deals win rate',
    text: 'Percentage of won deals out of the total number of completed deals.',
  },
  {
    itemType: 'tab',
    icon: 'verified',
    title: 'Deals won',
    text: 'Total number of won deals.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-unverified',
    title: 'Deals lost',
    text: 'Total number of lost deals.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-paid-outline',
    title: 'Avg. deal value',
    text: 'Average monetary value of all deals.',
  },
  {
    itemType: 'tab',
    icon: 'verified',
    title: 'Won deals value',
    text: 'Total monetary value of all won deals.',
  },
  {
    itemType: 'tab',
    icon: 'tmi-unverified',
    title: 'Lost deals value',
    text: 'Total monetary value of all lost deals.',
  },
]

export const replaceInsightCategories: SelectEntityCategoryType[] = [
  {
    title: 'Campaigns',
    name: 'campaigns',
    icon: 'tmi-campaign',
    data: replaceInsightCampaignsData,
  },
  {
    title: 'Messenger',
    name: 'messenger',
    icon: 'chat',
    data: replaceInsightMessengerData,
  },
  {
    title: 'Helpdesk',
    name: 'helpdesk',
    icon: 'email',
    data: replaceInsightHelpdeskData,
  },
  {
    title: 'Contacts',
    name: 'contacts',
    icon: 'group',
    data: replaceInsightContactsData,
  },
  {
    title: 'Tasks',
    name: 'tasks',
    icon: 'tmi-layers-tasks',
    data: replaceInsightTasksData,
  },
  {
    title: 'Deals',
    name: 'deals',
    icon: 'tmi-sale',
    data: replaceInsightDealsData,
  },
]
